<template>
  <div class="tile tile__grid">
    <div class="flex__between">
      <h3>Billing</h3>
      <button
        v-if="canEdit"
        class="btn btn__red btn__sm"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <span>Admins may add back the award balance to the billing user</span>
    <label>
      Billing User
      <span>{{ active.billingName }}</span>
    </label>
    <label>
      Using Award Balance
      <span
        v-if="!editing || !canEdit"
      >{{ active.useAwardBalance ? "Yes" : "No" }}</span>
      <select v-if="editing && canEdit" v-model="form.useAwardBalance">
        <option :value="true">Yes</option>
        <option :value="false">No</option>
      </select>
    </label>
    <label>
      Current Award Balance
      <span>${{ selectedBillingUser ? selectedBillingUser.awardBalance.toFixed(2) : parseInt(0).toFixed(2) }}</span>
    </label>
    <label>
      Award Amount Used
      <span>${{ active.awardAmountUsed.toFixed(2) }}</span>
    </label>
    <label></label>
    <div v-if="editing && canEdit" class="flex__between">
      <button class="btn btn__red btn__sm" @click="toggleEditing">Cancel</button>
      <button class="btn btn__green btn__sm" @click="submit">Done</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active", "canEdit"],
  data() {
    return {
      editing: false,
      form: {
        _id: this.active ? this.active._id : null,
        useAwardBalance: this.active ? this.active.useAwardBalance : false,
        awardAmountUsed: this.active ? this.active.awardAmountUsed : 0,
        balanceDue: this.active ? this.active.balanceDue : 0,
        billingUser: this.active ? this.active.billingUser : null
      },
      users: null
    };
  },
  computed: {
    selectedBillingUser() {
      if (!this.users) return null;
      return this.users.filter(item => item._id === this.active.billingUser)[0];
    }
  },
  methods: {
    ...mapActions([
      "startSetIsSubmitting",
      "startUpdateOrder",
      "startUpdateUser",
      "loadDelegates"
    ]),
    toggleEditing() {
      this.editing = !this.editing;
    },
    async submit() {
      if (!this.users) return;
      if (this.active.useAwardBalance === this.form.useAwardBalance) return;
      this.startSetIsSubmitting({ bool: true, heading: "Submitting" });
      const form = JSON.parse(JSON.stringify(this.form));
      let awardAmountChange;
      if (!this.form.useAwardBalance) {
        form.awardAmountUsed = 0;
        form.balanceDue = this.active.totalCost;
        awardAmountChange = this.active.awardAmountUsed;
      } else {
        const balanceDue =
          this.active.totalCost - this.selectedBillingUser.awardBalance;
        form.balanceDue = balanceDue > 0 ? balanceDue : 0;
        form.awardAmountUsed = this.active.totalCost - form.balanceDue;
        awardAmountChange = form.balanceDue - this.active.totalCost;
      }
      try {
        await this.startUpdateOrder(form);
        await this.updateUser(awardAmountChange);
        this.startLoadDelegates();
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    async updateUser(awardAmountChange) {
      if (!this.selectedBillingUser) return Promise.reject("No user found");
      const newBal = this.selectedBillingUser.awardBalance + awardAmountChange;
      const form = {
        _id: this.selectedBillingUser._id,
        displayName: this.selectedBillingUser.displayName,
        awardBalance: newBal > 0 ? newBal : 0
      };
      try {
        await this.startUpdateUser(form);
        return Promise.resolve("success");
      } catch (err) {
        return Promise.reject(err);
      }
    },
    async startLoadDelegates() {
      try {
        this.users = await this.loadDelegates();
        return Promise.resolve("success");
      } catch (err) {
        console.log(err);
        return Promise.reject(err);
      }
    }
  },
  mounted() {
    this.startLoadDelegates();
  }
};
</script>

<style scoped lang="scss">
.tile__grid label {
  font-weight: bold;
  @include grid($cols: 1fr 1fr, $col-gap: 10px, $align: center);
  span {
    font-weight: normal;
    text-align: right;
  }
}
</style>