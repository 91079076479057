<template>
  <section class="margin__md">
    <section class="container">
      <div class="tile-heading">
        <h2>Order Completion</h2>
        <hr />
      </div>
      <div class="confirm-details--tiles">
        <Details :active="active" :canEdit="canEdit" />
        <Billing :active="active" :canEdit="canEdit" />
      </div>
    </section>
  </section>
</template>

<script>
import Details from "./Details";
import Billing from "./Billing";
export default {
  props: ["active", "canEdit"],
  components: {
    Details,
    Billing
  }
};
</script>