<template>
  <div class="tile tile__grid">
    <div class="flex__between">
      <h3>Order Details</h3>
      <button
        v-if="canEdit"
        class="btn btn__red btn__sm"
        @click="toggleEditing"
      >{{ editing ? "Cancel" : "Edit" }}</button>
    </div>
    <span v-if="!canMarkComplete">Cannot mark order complete until all order items are completed</span>
    <label>
      Status
      <span v-if="!editing || !canEdit">{{ active.status }}</span>
      <select v-if="editing && canEdit" v-model="form.status">
        <!-- <option value="Started">Started</option> -->
        <option
          value="Submitted"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Submitted</option>
        <option
          value="Ordered"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Ordered</option>
        <option
          value="In Progress"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >In Progress</option>
        <option
          value="Awaiting Input"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Awaiting Input</option>
        <option
          value="Ready to Ship"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Ready to Ship</option>
        <option
          value="Sent"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Sent</option>
        <option
          value="Beer"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Beer</option>
        <option
          value="Pre-Order"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete'"
        >Pre-Order</option>
        <option
          value="Complete"
          :disabled="active.status === 'Complete' || active.status === 'Admin Complete' || !canMarkComplete"
        >Complete</option>
        <option
          value="Admin Complete"
          :disabled="active.status === 'Admin Complete' || !canMarkComplete"
        >Admin Complete</option>
      </select>
    </label>
    <label>
      Total Cost
      <span>${{ active.totalCost.toFixed(2) }}</span>
    </label>
    <label>
      Balance Due
      <span>${{ active.balanceDue.toFixed(2) }}</span>
    </label>
    <div v-if="editing && canEdit" class="flex__between">
      <button class="btn btn__red btn__sm" @click="toggleEditing">Cancel</button>
      <button class="btn btn__green btn__sm" @click="submit">Done</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["active", "canEdit"],
  data() {
    return {
      editing: false,
      form: {
        _id: this.active ? this.active._id : null,
        status: this.active ? this.active.status : null
      }
    };
  },
  computed: {
    canMarkComplete() {
      //check if all orderItem statuses are "Completed"
      return this.active.orderItems
        .map(item => item.status === "Completed")
        .reduce((a, b) => a && b, true);
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "startUpdateOrder"]),
    toggleEditing() {
      this.editing = !this.editing;
    },
    async submit() {
      this.validateForm();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Submitting" });
      try {
        await this.startUpdateOrder(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.toggleEditing();
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.tile__grid label {
  font-weight: bold;
  @include grid($cols: 1fr 1fr, $col-gap: 10px, $align: center);
  span {
    font-weight: normal;
    text-align: right;
  }
}
</style>