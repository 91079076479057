import { render, staticRenderFns } from "./Billing.vue?vue&type=template&id=8975540e&scoped=true&"
import script from "./Billing.vue?vue&type=script&lang=js&"
export * from "./Billing.vue?vue&type=script&lang=js&"
import style0 from "./Billing.vue?vue&type=style&index=0&id=8975540e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8975540e",
  null
  
)

export default component.exports